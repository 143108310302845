<template>
  <div class="m-1">
    <b-row class="match-height">
      <b-col>

        <b-row class="match-height">
          <b-col
            md="6"
            class="mb-1"
          >
            <h6 class="text-muted text-uppercase font-small-3">
              {{ $t('customers.tabs.additional-info.label.address') }}
            </h6>
            <span v-if="!customer.address">-</span>
            <div v-else>
              {{ customer.address.addressLine1 }}<br>
              {{ customer.address.zipCode }} {{ customer.address.city }}
            </div>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="mb-1">
              <h6 class="text-muted text-uppercase font-small-3">
                {{ $t('customers.tabs.additional-info.label.invoice-details') }}
              </h6>
              <span v-if="!customer.invoiceDetails">-</span>
              <div v-else>
                <strong v-if="customer.invoiceDetails.companyName">
                  {{ customer.invoiceDetails.companyName }}<br>
                </strong>
                {{ customer.invoiceDetails.addressLine1 }}
                <br>{{ customer.invoiceDetails.zipCode }} {{ customer.invoiceDetails.city }}
                <span v-if="customer.invoiceDetails.taxIdentifier">
                  <br>{{ $t('customers.tabs.additional-info.label.tax-identifier') }}: {{ customer.invoiceDetails.taxIdentifier }}
                </span>
              </div>
            </div>

            <div>
              <h6 class="text-muted text-uppercase font-small-3">
                {{ $t('customers.form.billing-details.settlements') }}
              </h6>
              <dl class="row mb-50">
                <dt class="col-sm-6">
                  {{ $t('customers.form.billing-details.settlements-time') }}
                </dt>
                <dd class="col-sm-6">
                  {{ settlementTimeOptions[customer.settlementTime] }}
                </dd>
                <dt class="col-sm-6">
                  {{ $t('customers.form.billing-details.payment-deadline') }}
                </dt>
                <dd class="col-sm-6">
                  {{ customer.paymentDeadline }}
                  {{ $tc('calendar.event.form.label.recurrence-interval-type.day', customer.paymentDeadline) }}
                </dd>
              </dl>
            </div>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        class="mb-1 mt-1"
      >
        <h4>{{ $t('customers.tabs.additional-info.label.settings') }}</h4>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        class="mb-1"
      >
        {{ $t('customers.tabs.additional-info.label.can-make-reservations') }}
        <strong v-if="customer.canMakeOnlineReservations">
          {{ $t('shared.yes') }}
        </strong>
        <strong v-else>
          {{ $t('shared.no') }}
        </strong>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        class="mb-1 mt-1"
      >
        <h4>{{ $t('customers.tabs.additional-info.label.consents') }}</h4>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        class="mb-1"
      >
        {{ $t('customers.tabs.additional-info.label.gdpr-consent') }}
        <strong v-if="customer.gdprConsent">
          {{ $t('shared.yes') }}
        </strong>
        <strong v-else>
          {{ $t('shared.no') }}
        </strong>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        class="mb-1"
      >
        {{ $t('customers.tabs.additional-info.label.communication-consent') }}
        <strong v-if="customer.communicationConsent">
          {{ $t('shared.yes') }}
        </strong>
        <strong v-else>
          {{ $t('shared.no') }}
        </strong>
      </b-col>
    </b-row>

  </div>
</template>
<script>

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settlementTimeOptions: {
        AtTheEndOfMonth: this.$t('customers.form.billing-details.settlement-time-options.at-the-end-of-month'),
        AtTheBeginningOfMonth: this.$t('customers.form.billing-details.settlement-time-options.at-the-beginning-of-month'),
        AfterEveryMeeting: this.$t('customers.form.billing-details.settlement-time-options.after-every-meeting'),
      },
    }
  },
}
</script>

<style scoped>

</style>
