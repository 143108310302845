<template>
  <div>
    <validation-observer
      ref="settlementFormValidation"
      #default="{ handleSubmit }"
    >
      <b-modal
        :id="`add-settlement-modal-${customer.id}`"
        :ref="`addSettlementModal-${customer.id}`"
        v-model="isShowingModal"
        :title="$t(`settlements.modal.title.${type}`)"
        :cancel-title="$t('shared.btn.cancel')"
        cancel-variant="outline-secondary"
        :ok-title="$t(`settlements.modal.btn.${type}`)"
        :ok-disabled="processing"
        @ok.prevent="handleSubmit(addSettlement)"
        @show="resetSettlement"
      >

        <form
          id="add-settlement-form"
          autocomplete="off"
          @submit.stop.prevent
        >

          <b-row class="mb-50">
            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('settlements.form.method')"
                rules="required"
              >
                <b-form-group
                  :label="$t('settlements.form.payment-method')"
                  label-for="payment-method"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-select
                    id="payment-method"
                    v-model="settlement.method"
                    :options="paymentMethodOptions"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('settlements.form.amount')"
                :rules="`required|min_value:${minAmount.toString()}`"
              >
                <b-form-group
                  :label="$t('settlements.form.amount')"
                  label-for="settlement-amount"
                  :state="getValidationState(validationContext)"
                >
                  <b-input-group
                    append="zł"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="settlement-amount"
                      v-model="settlement.amount"
                      type="number"
                      step="0.01"
                      value="0.00"
                      class="text-right"
                      placeholder="50.00"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>

          <b-row class="mb-50">
            <b-col>

              <validation-provider
                #default="validationContext"
                :name="$t('settlements.form.comment')"
                rules="max:250"
              >
                <b-form-group
                  :label="$t('settlements.form.comment')"
                  label-for="settlement-comment"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="settlement-comment"
                    v-model="settlement.comment"
                    type="text"
                    maxlength="251"
                    autocomplete="off"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-form-group
                :label="$t('settlements.form.payment-date')"
                label-for="settlement-payment-date"
              >
                <flat-pickr
                  id="end-date"
                  v-model="settlement.paymentDate"
                  class="form-control"
                  :config="specificDateFlatPickrSettings"
                />
              </b-form-group>

            </b-col>
          </b-row>

        </form>
      </b-modal>
    </validation-observer>

  </div>
</template>

<script>
import {
  BModal, BFormInvalidFeedback, BFormInput, BFormGroup, BRow, BCol, BInputGroup, BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, nextTick } from '@vue/composition-api'
import dayjs from 'dayjs'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@/libs/validations/validations'
import store from '@/store'
import {
  cloneNested, parseRequestError, strToDecimal,
} from '@/helpers/helpers'
import PaymentMethodsDictionary from '@/dictionary/PaymentMethodsDictionary'
import SettlementTypeDictionary from '@/dictionary/SettlementTypeDictionary'
import SettlementStatusDictionary from '@/dictionary/SettlementStatusDictionary'
import flatPickrSettings from '@/views/_global/calendar-event-sidebar/flatPickrSettings'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BInputGroup,

    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    calendarEventCustomers: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    paymentMethodOptions() {
      switch (this.type) {
        case SettlementTypeDictionary.INCOMING_PAYMENT:
          return [
            { value: PaymentMethodsDictionary.CASH, text: this.$t('shared.payment-method.cash') },
            { value: PaymentMethodsDictionary.BANK_TRANSFER, text: this.$t('shared.payment-method.bank_transfer') },
          ]
        case SettlementTypeDictionary.RETURN:
          return [
            { value: PaymentMethodsDictionary.CASH, text: this.$t('shared.payment-method.cash') },
            { value: PaymentMethodsDictionary.BANK_TRANSFER, text: this.$t('shared.payment-method.bank_transfer') },
          ]
        default:
          return []
      }
    },
  },
  setup(props, { emit }) {
    const settlementModel = {
      type: props.type,
      status: SettlementStatusDictionary.Paid,
      amount: null,
      method: PaymentMethodsDictionary.CASH,
      customer: props.customer.id,
      calendarEventCustomers: [],
      comment: null,
      paymentDate: dayjs().toISOString(),
    }

    const settlement = ref(cloneNested(settlementModel))
    const settlementFormValidation = ref(null)
    const addSettlementModal = ref(null)
    const isShowingModal = ref(null)
    const processing = ref(null)
    const phoneNumberValid = ref(true)
    const minAmount = ref(1)

    const handlePhoneNumberValidation = phoneObject => {
      phoneNumberValid.value = !!phoneObject.valid || !phoneObject.formatted
    }

    const resetSettlement = () => {
      settlement.value = cloneNested(settlementModel)
      setTimeout(() => {
        nextTick(() => {
          settlementFormValidation.value.reset()
        })
      }, 10)

      props.calendarEventCustomers.forEach(calendarEventCustomer => {
        settlement.value.amount += strToDecimal(calendarEventCustomer.settlement.amount)
        settlement.value.calendarEventCustomers.push(calendarEventCustomer.id)
      })

      if (props.calendarEventCustomers.length) {
        minAmount.value = cloneNested(settlement.value.amount)
      }
    }

    const addSettlement = () => {
      processing.value = true

      const clonedSettlement = cloneNested(settlement.value)

      if (props.type === SettlementTypeDictionary.RETURN) {
        clonedSettlement.amount *= -1
      }

      clonedSettlement.type = props.type
      clonedSettlement.paymentDate = dayjs(clonedSettlement.paymentDate).format()

      store.dispatch(
        'settlement/addPayment',
        clonedSettlement,
      )
        .then(response => {
          emit('settlement-added', response.data)
          resetSettlement()
          isShowingModal.value = false
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          processing.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetSettlement)

    const {
      specificDateFlatPickrSettings,
    } = flatPickrSettings()

    return {
      isShowingModal,
      settlement,
      addSettlementModal,
      processing,
      minAmount,

      phoneNumberValid,
      handlePhoneNumberValidation,

      addSettlement,
      resetSettlement,

      // validations
      specificDateFlatPickrSettings,
      settlementFormValidation,
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    }
  },
}
</script>

<style scoped>

</style>
