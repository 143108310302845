<template>
  <div>
    <b-row class="match-height app-tour-customer-view-statistics">
      <b-col>
        <b-card class="shadow-none">

          <rc-overlay :show="loading">
            <b-row class="match-height">
              <b-col
                cols="6"
                sm="3"
                class="mb-1"
              >
                <h4 class="mb-50 text-center">
                  {{ statistics.plannedMeetings }}
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.meetings-planned') }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                sm="3"
                class="mb-1"
              >
                <h4 class="mb-50 text-center">
                  {{ statistics.pastMeetings }}
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.meetings-ended') }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ statistics.customerAbsenceMeetings }}
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.absences') }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ statistics.canceledMeetingsByCustomer }} / {{ statistics.canceledMeetingsByCustomer + statistics.canceledMeetingsByTeacher }}
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.cancellations') }}
                  <rc-help :description="$t('customers.statistics.cancellations-description')" />
                </h6>
              </b-col>
            </b-row>

            <b-row class="match-height">
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ getIntegers(statistics.customerBalance) }}<span
                    class="font-small-4 text-muted"
                  >.{{ getDecimals(statistics.customerBalance) }} zł</span>
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.balance') }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ getIntegers(statistics.incomeToPay) }}<span
                    class="font-small-4 text-muted"
                  >.{{ getDecimals(statistics.incomeToPay) }} zł</span>
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.to-pay') }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ getIntegers(statistics.incomePaid) }}<span
                    class="font-small-4 text-muted"
                  >.{{ getDecimals(statistics.incomePaid) }} zł</span>
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.paid-total') }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                sm="3"
              >
                <h4 class="mb-50 text-center">
                  {{ customer.discountPercent }}%
                </h4>
                <h6 class="text-muted font-weight-bolder font-small-1 text-uppercase text-center">
                  {{ $t('customers.statistics.discount') }}
                </h6>
              </b-col>
            </b-row>
          </rc-overlay>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import { getDecimals, getIntegers } from '@/helpers/helpers'
import RcHelp from '@/layouts/components/rc/RcHelp.vue'

export default {
  components: {
    RcHelp,
    RcOverlay,
    BRow,
    BCol,
    BCard,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    statistics() {
      return this.$store.getters['customer/customerStatistics']
    },
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true

      this.$store.dispatch('customer/fetchCustomerStatistics', {
        customerId: this.customer.id,
      })
        .finally(() => {
          this.loading = false
        })
    },
    getIntegers,
    getDecimals,
  },
}
</script>

<style scoped>
.card {
  margin-bottom: 1rem;
}
</style>
