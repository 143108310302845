<template>
  <div>
    <add-settlement-modal
      :type="'incoming_payment'"
      :customer="customer"
      :calendar-event-customers="selectedRows"
      @settlement-added="handleNewSettlementAdded"
    />

    <b-row class="mx-50">
      <b-col class="mb-50">
        <div>
          <b-button
            block
            :variant="selectedTab === 'future' ? 'primary': 'outline-primary'"
            @click="changeTab('future')"
          >
            {{ $t('customers.tabs.history.future') }}
          </b-button>
        </div>
      </b-col>

      <b-col class="mb-50">
        <div>
          <b-button
            block
            :variant="selectedTab === 'past' ? 'primary': 'outline-primary'"
            @click="changeTab('past')"
          >
            {{ $t('customers.tabs.history.past') }}
          </b-button>
        </div>
      </b-col>

      <b-col
        v-if="me.roles.includes(role.admin)"
        class="mb-50"
        cols="12"
        sm="2"
      >
        <b-dropdown
          right
          block
          variant="outline-primary"
          class="dropdown-icon-wrapper"
        >
          <template #button-content>
            <feather-icon
              icon="MenuIcon"
              class="align-middle"
            />
          </template>
          <b-dropdown-item
            v-b-modal="`add-settlement-modal-${customer.id}`"
          >
            {{ $t('settlements.btn.add-payment') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row class="mx-50">
      <b-col>

        <div
          v-if="loading"
          class="alert alert-secondary p-1 text-center"
        >
          {{ $t(`shared.loading`) }}
        </div>
        <div
          v-else-if="customerCalendarEvents.length === 0"
          class="alert alert-info p-1 text-center"
        >
          <span v-if="selectedTab === 'future'">
            {{ $t(`customers.tabs.history.no-future-events-with`) }}
            {{ customer.firstName }}
          </span>
          <span v-if="selectedTab === 'past'">
            {{ $t(`customers.tabs.history.no-past-events-with`) }}
            {{ customer.firstName }}
          </span>
        </div>
      </b-col>
    </b-row>

    <b-table
      v-if="customerCalendarEvents.length > 0"
      responsive
      selectable
      :items="customerCalendarEvents"
      :fields="fields"
      sort-by="startAt"
      :sort-desc="selectedTab === 'past'"
      class="mb-0"
      @row-selected="onRowSelected"
    >

      <template
        #cell(selected)="data"
      >
        <feather-icon
          v-if="data.rowSelected"
          icon="CheckCircleIcon"
          size="20"
        />
        <feather-icon
          v-else
          icon="CircleIcon"
          size="20"
        />
      </template>

      <template
        #cell(service)="data"
      >
        <div class="title-column">
          <span
            v-b-tooltip.hover
            :title="data.item.calendarEvent.service.shortDescription"
          >
            {{ data.item.calendarEvent.service.name }}
          </span>

          <b-badge
            v-if="data.item.calendarEvent.location"
            variant="light-secondary"
            class="text-nowrap ml-1"
            size="sm"
          >
            {{ $t(`shared.service-location.${data.item.calendarEvent.location}`) }}
          </b-badge>

          <b-badge
            v-if="data.item.calendarEvent.calendarEventSequence"
            variant="light-secondary"
            class="text-nowrap ml-1"
            size="sm"
          >
            {{ data.item.calendarEvent.ordinalNumber }} /
            {{ data.item.calendarEvent.calendarEventSequence.calendarEventsCount }}
          </b-badge>

          <small><br>
            {{ data.item.calendarEvent.subject }}
          </small>
        </div>
      </template>

      <template
        #cell(priceGross)="data"
      >
        <div
          v-if="data.item.settlement"
          class="text-right"
        >
          <b-badge
            :variant="`light-${getColorVariantByPaymentStatus(data.item.settlement.status)}`"
            class="text-nowrap"
          >
            {{ strToDecimal(data.item.settlement.amount) }} zł
          </b-badge>
          <div
            v-if="data.item.settlement.servicePriceType && data.item.settlement.servicePriceType !== 'PerMeeting'"
            class="text-nowrap font-small-2"
          >
            {{ $t(`shared.price-types.${data.item.settlement.servicePriceType}`).toLowerCase() }}
          </div>
        </div>
      </template>

      <template
        #cell(startAt)="data"
      >
        <span class="text-nowrap">
          {{
            dayjs(data.item.calendarEvent.startAt)
              .format($t('date-format.date'))
          }}<br>
          {{
            dayjs(data.item.calendarEvent.startAt)
              .format($t('date-format.time'))
          }}-{{
            dayjs(data.item.calendarEvent.endAt)
              .format($t('date-format.time'))
          }}
        </span>
      </template>

      <template
        #cell(location)="data"
      >
        <div class="text-right">
          <b-button
            :to="{ name: 'organization-meeting-view', params: { id: data.item.calendarEvent.id } }"
            variant="outline-primary"
            size="sm"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
      </template>

    </b-table>

  </div>
</template>
<script>
import dayjs from 'dayjs'
import { VBTooltip } from 'bootstrap-vue'
import AddSettlementModal from '@/views/_global/AddSettlementModal'
import { getColorVariantByPaymentStatus, strToDecimal } from '@/helpers/helpers'
import role from '@/auth/role'

export default {
  components: {
    AddSettlementModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedTab: 'future',
      selectedRows: [],
    }
  },
  computed: {
    customerCalendarEvents() {
      return this.$store.getters['customer/customerCalendarEvents']
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
    role() {
      return role
    },
    fields() {
      return [
        {
          key: 'selected',
          label: '',
          class: 'px-1',
          visible: true,
        },
        {
          key: 'service',
          label: this.$t('customers.tabs.history.service'),
          class: 'pl-0',
          visible: true,
        },
        {
          key: 'priceGross',
          label: this.$t('customers.tabs.history.price'),
          sortable: false,
          visible: this.me.roles.includes(role.admin),
        },
        {
          key: 'startAt',
          label: this.$t('customers.tabs.history.time'),
          sortable: false,
          visible: true,
        },
        {
          key: 'location',
          label: '',
          sortable: false,
          visible: true,
        },
        // { key: 'status', label: this.$t('customers.tabs.history.status') },
      ].filter(item => item.visible)
    },
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      let params = {
        from: dayjs()
          .format(),
        orderBy: 'ce.startAt',
        orderDir: 'ASC',
      }

      if (this.selectedTab === 'past') {
        params = {
          to: dayjs()
            .format(),
          orderBy: 'ce.startAt',
          orderDir: 'DESC',
        }
      }

      this.$store.dispatch('customer/fetchCustomerCalendarEvents', {
        customerId: this.customer.id,
        params,
      })
        .finally(() => {
          this.loading = false
        })
    },
    changeTab(newTab) {
      this.selectedTab = newTab
      this.fetchData()
    },
    handleNewSettlementAdded() {
      this.fetchData()
      this.$store.dispatch('customer/fetchCustomerStatistics', {
        customerId: this.customer.id,
      })
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    getColorVariantByPaymentStatus,
    dayjs,
    strToDecimal,
  },
}
</script>

<style scoped>
.title-column {
  min-width: 180px;
}
</style>
