<template>
  <b-form-input
    v-model="content"
    class="border-0 p-0"
    rows="1"
    max-rows="10"
    :maxlength="maxlength"
    :placeholder="$t('shared.complete-the-information')"
    @keydown.enter="enterClicked"
    @update="updateValue"
  />
</template>
<script>

import { BFormInput } from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components: {
    BFormInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: null,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    valuePath: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: this.value,
      saved: true,
      timeout: null,
    }
  },
  beforeDestroy() {
    if (!this.saved) {
      this.$emit('input', this.content, this.prepareObject())
    }
  },
  methods: {
    updateValue() {
      this.saved = false
      this.$emit('updating', true)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('input', this.content, this.prepareObject())
        this.saved = true
      }, this.debounce)
    },
    enterClicked(e) {
      if (this.inline) {
        e.preventDefault()
      }
    },
    prepareObject() {
      let obj = null
      if (this.valuePath) {
        obj = _.set({}, this.valuePath, this.content)
      }

      return obj
    },
  },
}
</script>
<style lang="scss" scoped>
textarea {
  padding: 0 !important;
overflow-y: auto !important;
overflow-x: hidden !important;
  height: 25px;
  &:focus {
    box-shadow: none !important;
  }
}

::-webkit-input-placeholder {
  font-style: italic;
  opacity: .7
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
</style>
