<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="28"
    >
      {{ initials }}
    </b-avatar>
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ customer.lastName }} {{ customer.firstName }}
        <b-img
          v-if="customer.registered"
          v-b-tooltip.hover
          class="float-right mt-n25 border-0"
          height="24px"
          :src="icon"
          :title="$t('customer.is-registered')"
        />
      </h5>
      <!--      <p class="card-text text-truncate">-->
      <!--      </p>-->
    </div>
    <!--    <div-->
    <!--      class="chat-meta text-nowrap"-->
    <!--    >-->
    <!--      <b-badge-->
    <!--        pill-->
    <!--        variant="primary"-->
    <!--        class="float-right"-->
    <!--      >-->
    <!--        {{ 1 }}-->
    <!--      </b-badge>-->
    <!--    </div>-->
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { $themeConfig } from '../../../../themeConfig'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icon: $themeConfig.app.appIcon,
    }
  },
  computed: {
    initials() {
      return this.customer.firstName.charAt(0) + this.customer.lastName.charAt(0)
    },
  },
}
</script>
