<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Sidebar Content -->
      <div
        class="sidebar-content card"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search app-tour-customers-search-area">
          <div class="d-flex align-items-center w-100">
            <!-- Search -->
            <b-input-group class="input-group-merge w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                :placeholder="$t('shared.search')"
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <b-button
            variant="flat-primary"
            class="mx-2 mt-1 app-tour-customers-add-button"
            @click="$emit('add-new-customer')"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('customers.add-new') }}
          </b-button>

          <!-- Chats Title -->
          <h4 class="chat-list-title mt-1">
            {{ $t('customers.title') }}
            <b-badge
              v-show="filteredCustomers.length > 0"
              pill
              variant="light-primary"
              size="xs"
            >
              {{ filteredCustomers.length }}
            </b-badge>
            <b-button
              v-b-tooltip.hover
              :variant="showArchived ? 'primary' : 'flat-secondary'"
              class="btn-icon mt-n25 float-right app-tour-customers-toggle-archived-button"
              size="sm"
              :title="showArchived ? $t('shared.archived.hide') : $t('shared.archived.show')"
              @click="refetchData()"
            >
              <feather-icon icon="ArchiveIcon" />
            </b-button>
          </h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list app-tour-customers-list app-tour-customers-list-area">
            <customer-list-item
              v-for="customer in filteredCustomers"
              :key="customer.id"
              :customer="customer"
              tag="li"
              :class="{'active': activeCustomerId === customer.id, 'archived': customer.archived}"
              @click="$emit('open-customer-view', customer.id)"
            />
          </ul>

        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed, ref } from '@vue/composition-api'
import CustomerListItem from '@/views/organization/customers/CustomerListItem'
import store from '@/store'

export default {
  components: {
    BBadge,
    CustomerListItem,

    // BSV
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,

    // 3rd party
    VuePerfectScrollbar,

  },
  props: {
    customers: {
      type: Array,
      required: true,
    },
    activeCustomerId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const showArchived = computed(() => store.getters['customer/showArchived'])
    const disableShowArchived = ref(false)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = customer => {
      if (searchQuery.value.length === 0) {
        return true
      }

      let result = false
      searchQuery.value.split(' ').forEach(value => {
        if (!value) return false
        result = (customer.lastName || '').toLowerCase().includes(value.toLowerCase())
            || (customer.firstName || '').toLowerCase().includes(value.toLowerCase())
            || (customer.phone || '').toLowerCase().includes(value.toLowerCase())
            || (customer.email || '').toLowerCase().includes(value.toLowerCase())

        return result
      })
      return result
    }
    const filteredCustomers = computed(() => props.customers.filter(searchFilterFunction))

    const refetchData = () => {
      if (disableShowArchived.value) {
        return
      }
      disableShowArchived.value = true
      store.dispatch(
        'customer/fetchCustomers',
        { ignoreArchived: !showArchived.value ? 0 : 1 },
      )
        .finally(() => {
          disableShowArchived.value = false
        })
      store.commit('customer/SET_SHOW_ARCHIVED', !showArchived.value)
    }

    return {
      // Search Query
      searchQuery,
      filteredCustomers,
      showArchived,
      disableShowArchived,
      refetchData,

      // UI
      perfectScrollbarSettings,
    }
  },
}
</script>
