<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <rc-overlay
        :show="loading"
        no-fade
        variant="white"
        class="h-100"
      >

        <div
          v-if="!customer.id && !addingCustomer && !loading"
          class="start-chat-area"
        >
          <div class="mb-1 start-chat-icon">
            <feather-icon
              icon="UserIcon"
              size="56"
            />
          </div>
          <div>
            <span
              class="sidebar-toggle start-chat-text text-nowrap "
              :class="{'app-tour-customers-show-sidebar-button': !(mqShallShowLeftSidebar || largeScreen)}"
              @click="startConversation"
            >{{ $t('customers.select-customer') }}</span>
            <span> {{ $t('shared.or') }} </span>
            <span
              class="start-chat-text text-nowrap"
              @click="initAddCustomer"
            >{{ $t('customers.add-new-customer') }}</span>
          </div>
        </div>

        <!-- Customer Content -->
        <div
          v-else-if="customer.id || addingCustomer"
          class="active-chat"
        >
          <!-- Customer Navbar -->
          <div class="chat-navbar">
            <header class="chat-header">

              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">

                <!-- Toggle Icon -->
                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                </div>

                <b-avatar
                  v-if="!addingCustomer"
                  size="36"
                  class="mr-50"
                >
                  {{ currentCustomerInitials }}
                </b-avatar>
                <h6
                  v-if="!addingCustomer"
                  class="mb-0"
                >
                  {{ customer.lastName }} {{ customer.firstName }}<br>
                  <b-link
                    v-if="customer.phone"
                    :href="`tel:${customer.phone}`"
                    class="text-muted font-small-3"
                  >{{ customer.phone }}</b-link>
                  <span
                    v-else-if="customer.guardianPhone"
                    class="text-muted font-small-3"
                  >
                    {{ $t('customers.guardian') }}<span
                      v-if="customer.guardianLastName"
                      class="font-weight-bold"
                    >
                      {{ customer.guardianLastName }}</span><span
                      v-if="customer.guardianFirstName"
                      class="font-weight-bold"
                    > {{ customer.guardianFirstName }}</span>:
                    <b-link
                      :href="`tel:${customer.guardianPhone}`"
                    >{{ customer.guardianPhone }}</b-link>
                  </span>
                </h6>
              </div>

              <!-- Customer Actions -->
              <div class="d-flex align-items-center">

                <b-link
                  v-if="customer.phone"
                  :href="`tel:${customer.phone}`"
                >
                  <feather-icon
                    id="call-customer-icon"
                    icon="PhoneCallIcon"
                    size="17"
                    class="d-sm-block d-none mr-1"
                  />
                  <b-tooltip
                    target="call-customer-icon"
                    noninteractive
                  >
                    {{ $t('shared.call') }}
                  </b-tooltip>
                </b-link>

                <b-link
                  v-if="customer.email"
                  :href="`mailto:${customer.email}`"
                  target="_blank"
                >
                  <feather-icon
                    id="send-email-customer-icon"
                    icon="AtSignIcon"
                    size="17"
                    class="d-sm-block d-none mr-1"
                  />
                  <b-tooltip
                    target="send-email-customer-icon"
                    noninteractive
                  >
                    {{ $t('shared.send-email') }}
                  </b-tooltip>
                </b-link>

                <b-button
                  v-acl="[role.admin]"
                  variant="flat-primary"
                  class="mx-1 d-sm-block d-none app-tour-customer-edit-button"
                  @click="toggleEditMode"
                >
                  <feather-icon
                    id="edit-view-customer-toggle-icon"
                    :icon="editMode === true ? 'EyeIcon' : 'Edit2Icon'"
                    size="17"
                    class="d-sm-block d-none"
                  />
                  <b-tooltip
                    target="edit-view-customer-toggle-icon"
                    noninteractive
                  >
                    {{ editMode === true ? $t('shared.view') : $t('shared.edit') }}
                  </b-tooltip>
                </b-button>

                <b-button
                  v-if="editMode"
                  variant="success"
                  class="mr-1 mr-sm-0 app-tour-customers-save-button"
                  @click="$refs.customerForm.saveCustomer()"
                >
                  <feather-icon icon="SaveIcon" />
                  <span class="ml-50 d-none d-sm-inline-block">
                    {{ $t('shared.btn.save') }}
                  </span>
                </b-button>

                <div class="dropdown d-sm-none d-block">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                    class="app-tour-customers-customer-menu-icon"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="customer.phone"
                      :href="`tel:${customer.phone}`"
                      class="d-sm-none d-block"
                    >
                      {{ $t('shared.call') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="customer.email"
                      :href="`mailto:${customer.email}`"
                      target="_blank"
                      class="d-sm-none d-block"
                    >
                      {{ $t('shared.send-email') }}
                    </b-dropdown-item>
                    <b-dropdown-divider class="d-sm-none d-block" />
                    <b-dropdown-item
                      class="d-sm-none d-block"
                      @click="toggleEditMode"
                    >
                      {{ editMode === true ? $t('shared.view') : $t('shared.edit') }}
                    </b-dropdown-item>
                  <!--                  <b-dropdown-item>-->
                  <!--                    Archive-->
                  <!--                  </b-dropdown-item>-->
                  </b-dropdown>
                </div>
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <customer-form
              v-if="editMode"
              ref="customerForm"
              :customer="customer"
              @customer-added="customerAddedHandler"
              @customer-saved="customerSavedHandler"
              @leaving-adding-form="addingCustomer = false"
            />
            <customer-view
              v-else
              :customer="customer"
            />
          </vue-perfect-scrollbar>
        </div>

      </rc-overlay>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <customer-left-sidebar
        :customers="customers"
        :active-customer-id="customer ? customer.id : null"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @open-customer-view="openCustomerView"
        @add-new-customer="initAddCustomer"
      />
    </portal>
  </div>
</template>

<script>
import { computed, nextTick, ref } from '@vue/composition-api'
import {
  BAvatar, BButton, BDropdown, BDropdownDivider, BDropdownItem, BLink, BTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import CustomerLeftSidebar from './CustomerLeftSidebar.vue'
import router from '@/router'
import CustomerForm from '@/views/organization/customers/CustomerForm'
import CustomerView from '@/views/organization/customers/CustomerView'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import role from '@/auth/role'

export default {
  components: {
    CustomerView,
    CustomerForm,

    RcOverlay,

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BLink,
    BTooltip,
    BButton,
    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    CustomerLeftSidebar,
  },
  computed: {
    customers() {
      return this.$store.getters['customer/getCustomers']
    },
    customer() {
      return this.$store.getters['customer/getCustomer']
    },
    currentCustomerInitials() {
      return this.customer.firstName.charAt(0) + this.customer.lastName.charAt(0)
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
    role() {
      return role
    },
  },
  beforeMount() {
    this.$store.dispatch('customer/fetchCustomers')
    if (this.$route.params.customerId) {
      this.loading = true
      this.$store.commit('customer/SET_CUSTOMER', {})
      this.$store.dispatch(
        'customer/fetchCustomer',
        this.$route.params.customerId,
      )
        .then(() => { this.loading = false })
    }
  },
  setup() {
    const atLeastOneCustomerLoaded = ref(false)
    const loading = ref(false)
    const addingCustomer = ref(false)
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const resetCustomerView = () => {
      loading.value = false
      // const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      // scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const editMode = ref(router.currentRoute.name === 'customers-edit')
    const toggleEditMode = () => {
      editMode.value = !editMode.value
      window.history.pushState(null, null, `/organization/customers/${store.getters['customer/getCustomer'].id}/${editMode.value === true ? 'edit' : 'view'}`)
    }

    const openCustomerView = customerId => {
      if (router.currentRoute.name === 'customers-view' && customerId === store.getters['customer/getCustomer'].id) {
        return
      }

      editMode.value = false
      window.history.pushState(null, null, `/organization/customers/${customerId}/view`)
      loading.value = true
      store.commit('customer/SET_CUSTOMER', {})
      store.dispatch('customer/fetchCustomer', customerId)
        .then(() => {
          nextTick(() => { resetCustomerView() })
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    const initAddCustomer = () => {
      addingCustomer.value = true
      editMode.value = true
      store.dispatch('customer/resetCustomer')
      mqShallShowLeftSidebar.value = false
    }

    const customerAddedHandler = customer => {
      openCustomerView(customer.id)
      addingCustomer.value = false
      store.dispatch('customer/fetchCustomers')
    }

    const customerSavedHandler = customer => {
      if (!customer.archived) {
        return
      }
      store.dispatch('customer/resetCustomer')
      store.dispatch('customer/fetchCustomers')
      addingCustomer.value = false
      editMode.value = false
      window.history.pushState(null, null, '/organization/customers')
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
      suppressScrollX: true,
    }

    const largeScreen = computed(() => store.state.app.windowWidth >= $themeBreakpoints.lg)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    if (router.currentRoute.name === 'customers' && store.state.app.windowWidth < $themeBreakpoints.lg) {
      mqShallShowLeftSidebar.value = true
    }
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      atLeastOneCustomerLoaded,
      loading,
      addingCustomer,

      openCustomerView,
      toggleEditMode,
      customerAddedHandler,
      customerSavedHandler,
      initAddCustomer,

      // UI
      editMode,
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      largeScreen,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "../../../assets/scss/base/pages/app-customers";
@import "../../../assets/scss/base/pages/app-customers-list";
</style>
