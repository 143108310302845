<template>
  <div>
    <add-settlement-modal
      :type="settlementType"
      :customer="customer"
      @settlement-added="handleNewSettlementAdded"
    />

    <b-row
      v-if="!loading"
      class="mr-1 mb-1"
    >
      <b-col>
        <div class="text-right">
          <b-button-group size="sm">
            <b-dropdown
              v-b-modal="`add-settlement-modal-${customer.id}`"
              variant="primary"
              right
              split
              :text="$t('settlements.btn.add-payment')"
              @click="settlementType = 'incoming_payment'"
            >
              <b-dropdown-item
                v-b-modal="`add-settlement-modal-${customer.id}`"
                block
                @click="settlementType = 'return'"
              >
                {{ $t('settlements.btn.add-return') }}
              </b-dropdown-item>
              <!--              <b-dropdown-item-->
              <!--                v-b-modal.add-settlement-modal-->
              <!--                block-->
              <!--                @click="settlementType = 'correction'"-->
              <!--              >-->
              <!--                {{ $t('settlements.btn.add-correction') }}-->
              <!--              </b-dropdown-item>-->
            </b-dropdown>
          </b-button-group>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div
          v-if="loading"
          class="alert alert-secondary p-1 text-center mb-1 mx-1"
        >
          {{ $t(`shared.loading`) }}
        </div>
        <div
          v-else-if="customerBalanceHistories.length === 0"
          class="alert alert-info p-1 text-center mb-1 mx-1"
        >
          {{ $t(`customers.tabs.settlements.no-balance-history`) }}
        </div>
      </b-col>
    </b-row>

    <balance-history-table :customer-balance-histories="customerBalanceHistories" />
  </div>
</template>
<script>
import {
  BButtonGroup, BCol, BDropdown, BDropdownItem, BRow, VBModal,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import SettlementTypeDictionary from '@/dictionary/SettlementTypeDictionary'
import PaymentMethodsDictionary from '@/dictionary/PaymentMethodsDictionary'
import AddSettlementModal from '@/views/_global/AddSettlementModal'
import { strToDecimal } from '@/helpers/helpers'
import BalanceHistoryTable from '@/views/_global/settlements/BalanceHistoryTable'

export default {
  components: {
    BalanceHistoryTable,
    BRow,
    BCol,
    BButtonGroup,
    BDropdown,
    BDropdownItem,

    AddSettlementModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      settlementType: SettlementTypeDictionary.INCOMING_PAYMENT,
    }
  },
  computed: {
    customerBalanceHistories() {
      return this.$store.getters['customer/customerBalanceHistories']
    },
    totalRows() {
      return this.customerBalanceHistories.length
    },
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      const params = {
        // from: dayjs()
        //   .format(),
        // to: dayjs()
        //     .format(),
        orderBy: 'cbh.createdAt',
        orderDir: 'desc',
      }

      this.$store.dispatch('customer/fetchCustomerBalanceHistories', {
        customerId: this.customer.id,
        params,
      })
        .finally(() => {
          this.loading = false
        })
    },
    handleNewSettlementAdded() {
      this.fetchData()
      this.$store.dispatch('customer/fetchCustomerStatistics', {
        customerId: this.customer.id,
      })
    },
    isIncomingSettlement(method) {
      return [PaymentMethodsDictionary.CASH, PaymentMethodsDictionary.BANK_TRANSFER, PaymentMethodsDictionary.ONLINE_PAYMENT]
        .includes(method)
    },
    dayjs,
    strToDecimal,
  },
}
</script>
