<template>
  <div>

    <validation-observer ref="customerFormValidation">
      <form
        id="editCustomerForm"
        @submit.prevent
      >
        <b-row class="match-height">

          <b-col
            xl="7"
            md="12"
          >
            <b-card
              class="shadow-none app-tour-customers-edit-base-information"
              :title="$t('customers.form.base-information.title')"
            >

              <b-alert
                :show="customer.registered"
                class="p-1"
                variant="info"
              >
                {{ $t('services.form.base-information.customer-registered-info') }}
              </b-alert>

              <b-row class="mb-50">
                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.base-information.first-name')"
                    rules="required|min:2"
                  >
                    <label for="firstName">
                      {{ $t('customers.form.base-information.first-name') }}
                    </label>
                    <b-form-input
                      id="firstName"
                      v-model="customer.firstName"
                      type="text"
                      minlength="2"
                      maxlength="50"
                      :placeholder="$t('customers.form.base-information.first-name')"
                      autocomplete="off"
                      :disabled="customer.registered"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.base-information.last-name')"
                    rules="required|min:2"
                  >
                    <label for="lastName">
                      {{ $t('customers.form.base-information.last-name') }}
                    </label>
                    <b-form-input
                      id="lastName"
                      v-model="customer.lastName"
                      type="text"
                      minlength="2"
                      maxlength="50"
                      :placeholder="$t('customers.form.base-information.last-name')"
                      autocomplete="off"
                      :disabled="customer.registered"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.contact-information.phone')"
                    :rules="!isThereAtLeastOneContactInformation ? 'required' : ''"
                  >
                    <label for="phoneNumber">
                      {{ $t('customers.form.contact-information.phone') }}
                    </label>
                    <vue-tel-input
                      v-model="customer.phone"
                      mode="international"
                      :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                      :input-options="{ placeholder: '', showDialCode: false }"
                      :valid-characters-only="true"
                      :disabled="customer.registered"
                      @validate="handlePhoneNumberValidation"
                    />
                    <small
                      v-if="errors.length"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                    <small
                      v-else-if="!phoneNumberValid"
                      class="text-danger"
                    >{{ $t('validations.messages.phone-number-invalid') }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.contact-information.email')"
                    :rules="!isThereAtLeastOneContactInformation ? 'required|email' : 'email'"
                  >
                    <label for="email">
                      {{ $t('customers.form.contact-information.email') }}
                    </label>
                    <b-form-input
                      id="email"
                      v-model="customer.email"
                      type="text"
                      :placeholder="$t('customers.form.contact-information.email')"
                      autocomplete="off"
                      :disabled="customer.registered"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  xl="12"
                  class="mb-50"
                >
                  <label for="email">
                    {{ $t('customers.form.base-information.birth-date') }}
                  </label>
                  <flat-pickr
                    id="customerBirthDate"
                    v-model="customer.birthDate"
                    class="form-control"
                    :config="customerBirthDateFlatPickrSettings"
                  />
                </b-col>
              </b-row>

            </b-card>
          </b-col>

          <b-col
            xl="5"
            md="12"
          >
            <b-card
              class="shadow-none app-tour-customers-edit-settings"
              :title="$t('customers.form.settings.title')"
            >

              <div v-acl="role.admin">
                <b-row class="mb-50">
                  <b-col>
                    <label for="discountPercent">
                      {{ $t('customers.form.settings.discount') }}
                    </label>
                    <b-form-spinbutton
                      id="discountPercent"
                      v-model="customer.discountPercent"
                      :formatter-fn="discountFormatter"
                      min="0"
                      max="100"
                      style="max-width: 150px"
                    />
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col>
                    <b-form-group
                      :label="$t('customers.form.settings.discount-rounding')"
                      label-for="settlements-time"
                    >
                      <v-select
                        v-model="customer.discountRoundType"
                        input-id="discountRoundType"
                        :clearable="false"
                        :options="roundTypeOptions"
                        :searchable="false"
                        :reduce="(option) => option.id"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <b-row class="mb-50">
                <b-col>
                  <label
                    for="canMakeOnlineReservations"
                    class="w-100 font-medium-1 cursor-pointer"
                  >
                    <b-form-checkbox
                      id="canMakeOnlineReservations"
                      v-model="customer.canMakeOnlineReservations"
                      class="custom-control-success float-left"
                      name="check-button"
                      switch
                    />
                    {{ $t('customers.form.settings.can-make-online-reservations') }}
                  </label>
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>
                  <label
                    for="communicationConsent"
                    class="w-100 font-medium-1 cursor-pointer"
                  >
                    <b-form-checkbox
                      id="communicationConsent"
                      v-model="customer.communicationConsent"
                      class="custom-control-success float-left"
                      name="check-button"
                      switch
                    />
                    {{ $t('customers.form.settings.communication-consent') }}
                  </label>
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col>
                  <label
                    for="gdprConsent"
                    class="w-100 font-medium-1 cursor-pointer"
                  >
                    <b-form-checkbox
                      id="gdprConsent"
                      v-model="customer.gdprConsent"
                      class="custom-control-success float-left"
                      name="check-button"
                      switch
                    />
                    {{ $t('customers.form.settings.gdpr-consent') }}
                  </label>
                </b-col>
              </b-row>

            </b-card>
          </b-col>

        </b-row>

        <b-row class="match-height">

          <b-col
            md="6"
            cols="12"
          >
            <b-card
              :title="$t('customers.form.guardian.title')"
              class="shadow-none app-tour-customers-edit-guardian"
            >

              <b-row class="mb-50">
                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <label for="guardianFirstName">
                    {{ $t('customers.form.guardian.first-name') }}
                  </label>
                  <b-form-input
                    id="guardianFirstName"
                    v-model="customer.guardianFirstName"
                    type="text"
                    minlength="2"
                    maxlength="50"
                    :placeholder="$t('customers.form.guardian.first-name')"
                    autocomplete="off"
                    :disabled="customer.registered"
                  />
                </b-col>

                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <label for="guardianLastName">
                    {{ $t('customers.form.guardian.last-name') }}
                  </label>
                  <b-form-input
                    id="guardianLastName"
                    v-model="customer.guardianLastName"
                    type="text"
                    minlength="2"
                    maxlength="50"
                    :placeholder="$t('customers.form.guardian.last-name')"
                    autocomplete="off"
                    :disabled="customer.registered"
                  />
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col
                  xl="6"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.guardian.phone')"
                    :rules="!isThereAtLeastOneContactInformation ? 'required' : ''"
                  >
                    <label for="guardianPhone">
                      {{ $t('customers.form.guardian.phone') }}
                    </label>
                    <vue-tel-input
                      id="guardianPhone"
                      v-model="customer.guardianPhone"
                      mode="international"
                      :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                      :input-options="{ placeholder: '', showDialCode: false }"
                      :valid-characters-only="true"
                      :disabled="customer.registered"
                      @validate="handleGuardianPhoneNumberValidation"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col xl="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.guardian.email')"
                    :rules="!isThereAtLeastOneContactInformation ? 'required|email' : 'email'"
                  >
                    <label for="guardianEmail">
                      {{ $t('customers.form.guardian.email') }}
                    </label>
                    <b-form-input
                      id="guardianEmail"
                      v-model="customer.guardianEmail"
                      type="text"
                      :placeholder="$t('customers.form.guardian.email')"
                      autocomplete="off"
                      :disabled="customer.registered"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col></b-row>

            </b-card>

            <b-card
              :title="$t('customers.form.address.title')"
              class="shadow-none app-tour-customers-edit-address"
            >

              <b-row>
                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.address.street-number')"
                    rules="min:3|max:100"
                  >
                    <label for="addressAddressLine1">
                      {{ $t('customers.form.address.street-number') }}
                    </label>
                    <b-form-input
                      id="addressAddressLine1"
                      v-model="customer.address.addressLine1"
                      type="text"
                      minlength="3"
                      maxlength="100"
                      :placeholder="$t('customers.form.address.street-number')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <b-row>
                    <b-col
                      cols="5"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customers.form.address.zip')"
                        rules="min:3|max:10"
                      >
                        <label
                          for="addressZip"
                          class="text-nowrap"
                        >
                          {{ $t('customers.form.address.zip') }}
                        </label>
                        <b-form-input
                          id="addressZip"
                          v-model="customer.address.zipCode"
                          type="text"
                          minlength="3"
                          maxlength="10"
                          :placeholder="$t('customers.form.address.zip')"
                          autocomplete="chrome-off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="7"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customers.form.address.city')"
                        rules="min:3|max:50"
                      >
                        <label for="addressCity">
                          {{ $t('customers.form.address.city') }}
                        </label>
                        <b-form-input
                          id="addressCity"
                          v-model="customer.address.city"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('customers.form.address.city')"
                          autocomplete="chrome-off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>

            </b-card>

            <b-card
              v-if="customer.id"
              v-acl="role.admin"
              :title="$t('customers.form.danger-zone.title')"
              class="shadow-none border-danger text-danger app-tour-customers-edit-danger-zone"
            >

              <b-row>
                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <label
                    for="isCustomerArchived"
                    class="w-100 font-medium-1 cursor-pointer text-danger"
                  >
                    <b-form-checkbox
                      id="isCustomerArchived"
                      v-model="customer.archived"
                      class="custom-control-danger float-left"
                      name="check-button"
                      switch
                    />
                    {{ $t('customers.form.danger-zone.archived') }}
                  </label>
                </b-col>

              </b-row>

            </b-card>
          </b-col>

          <b-col
            md="6"
            cols="12"
          >
            <b-card
              :title="$t('customers.form.billing-details.title')"
              class="shadow-none app-tour-customers-edit-billing-details"
            >
              <h5>{{ $t('customers.form.billing-details.invoice-data') }}</h5>
              <b-row class="mb-1">
                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.billing-details.company-name')"
                    rules="min:3|max:100"
                  >
                    <label for="name">
                      {{ $t('customers.form.billing-details.company-name') }}
                    </label>
                    <b-form-input
                      id="name"
                      v-model="customer.invoiceDetails.companyName"
                      type="text"
                      minlength="3"
                      maxlength="100"
                      :placeholder="$t('customers.form.billing-details.company-name')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.billing-details.street-number')"
                    rules="min:3|max:100"
                  >
                    <label for="addressLine1">
                      {{ $t('customers.form.billing-details.street-number') }}
                    </label>
                    <b-form-input
                      id="addressLine1"
                      v-model="customer.invoiceDetails.addressLine1"
                      type="text"
                      minlength="3"
                      maxlength="100"
                      :placeholder="$t('customers.form.billing-details.street-number')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <b-row>
                    <b-col
                      cols="5"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customers.form.billing-details.zip')"
                        rules="min:3|max:10"
                      >
                        <label
                          for="zip"
                          class="text-nowrap"
                        >
                          {{ $t('customers.form.billing-details.zip') }}
                        </label>
                        <b-form-input
                          id="zip"
                          v-model="customer.invoiceDetails.zipCode"
                          type="text"
                          minlength="3"
                          maxlength="10"
                          :placeholder="$t('customers.form.billing-details.zip')"
                          autocomplete="chrome-off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="7"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('customers.form.billing-details.city')"
                        rules="min:3|max:50"
                      >
                        <label for="city">
                          {{ $t('customers.form.billing-details.city') }}
                        </label>
                        <b-form-input
                          id="city"
                          v-model="customer.invoiceDetails.city"
                          type="text"
                          minlength="3"
                          maxlength="50"
                          :placeholder="$t('customers.form.billing-details.city')"
                          autocomplete="chrome-off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customers.form.billing-details.tax-identifier')"
                    rules="alpha-num|length:10"
                  >
                    <label for="taxIdentifier">
                      {{ $t('customers.form.billing-details.tax-identifier') }}
                    </label>
                    <cleave
                      id="taxIdentifier"
                      v-model="customer.invoiceDetails.taxIdentifier"
                      class="form-control"
                      :options="inputMasks.taxIdentifier"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

              </b-row>

              <diV v-acl="role.admin">
                <h5>{{ $t('customers.form.billing-details.settlements') }}</h5>
                <b-row>

                  <b-col
                    cols="12"
                    class="mb-50"
                  >
                    <b-form-group
                      :label="$t('customers.form.billing-details.settlements-time')"
                      label-for="settlements-time"
                    >
                      <v-select
                        v-model="customer.settlementTime"
                        input-id="settlements-time"
                        :clearable="false"
                        :options="settlementTimeOptions"
                        :searchable="false"
                        :reduce="(option) => option.id"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    class="mb-50"
                  >
                    <b-form-group
                      :label="$t('customers.form.billing-details.payment-deadline')"
                      label-for="payment-deadline"
                    >
                      <b-input-group
                        :append="$tc('calendar.event.form.label.recurrence-interval-type.day', customer.paymentDeadline)"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="payment-deadline"
                          v-model="customer.paymentDeadline"
                          type="number"
                          step="1"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                </b-row>
              </diV>

            </b-card>

            <b-card
              v-acl="role.admin"
              :title="$t('customers.form.cancellation-rules.title')"
              class="shadow-none app-tour-customers-edit-cancellation-rules"
            >
              <b-row class="mb-50">
                <b-col>
                  <label
                    for="canCancelReservations"
                    class="w-100 font-medium-1 cursor-pointer"
                  >
                    <b-form-checkbox
                      id="canCancelReservations"
                      v-model="customer.canCancelReservations"
                      class="custom-control-success float-left"
                      name="check-button"
                      switch
                      @input="val => !val ? customer.freeCancellationMinHours = null : null"
                    />
                    {{ $t('customers.form.cancellation-rules.label.can-cancel') }}
                  </label>
                </b-col>
              </b-row>

              <b-row class="mb-50">
                <b-col
                  cols="12"
                  class="mb-50"
                >
                  <b-form-group
                    :label="$t('customers.form.cancellation-rules.label.free-cancellation-min-hours')"
                    label-for="free-cancellation-min-hours"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('customers.form.cancellation-rules.label.free-cancellation-min-hours')"
                      :rules="customer.canCancelReservations ? 'required' : ''"
                    >
                      <b-input-group
                        :append="$tc('shared.plural.hours-no-value', parseInt(customer.freeCancellationMinHours))"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="free-cancellation-min-hours"
                          v-model="customer.freeCancellationMinHours"
                          type="number"
                          step="1"
                          :disabled="!customer.canCancelReservations"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import Cleave from 'vue-cleave-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { Polish } from 'flatpickr/dist/l10n/pl'
import flatPickr from 'vue-flatpickr-component'
import { parseRequestError } from '@/helpers/helpers'
import {
  alphaNum, digits, length, required,
} from '@/libs/validations/validations'
import { fixDateTimezone } from '@/helpers/dateTimeHelper'
import role from '@/auth/role'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  components: {
    Cleave,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      digits,
      length,
      phoneNumberValid: true,
      guardianPhoneNumberValid: true,
      inputMasks: {
        taxIdentifier: {
          blocks: [2, 3, 3, 2, 2],
          uppercase: true,
        },
      },
      settlementTimeOptions: [
        { id: 'AtTheEndOfMonth', label: this.$t('customers.form.billing-details.settlement-time-options.at-the-end-of-month') },
        { id: 'AtTheBeginningOfMonth', label: this.$t('customers.form.billing-details.settlement-time-options.at-the-beginning-of-month') },
        { id: 'AfterEveryMeeting', label: this.$t('customers.form.billing-details.settlement-time-options.after-every-meeting') },
        { id: 'InAdvanceForAllScheduledMeetings', label: this.$t('customers.form.billing-details.settlement-time-options.in-advance-for-all-scheduled-meetings') },
      ],
      roundTypeOptions: [
        { id: 'NoRounding', label: this.$t('dictionary.rounding.option.NoRounding') },
        { id: 'ToNearestOne', label: this.$t('dictionary.rounding.option.ToNearestOne') },
        { id: 'ToNearestFive', label: this.$t('dictionary.rounding.option.ToNearestFive') },
        { id: 'ToNearestTen', label: this.$t('dictionary.rounding.option.ToNearestTen') },
        { id: 'UpToNearestOne', label: this.$t('dictionary.rounding.option.UpToNearestOne') },
        { id: 'UpToNearestFive', label: this.$t('dictionary.rounding.option.UpToNearestFive') },
        { id: 'UpToNearestTen', label: this.$t('dictionary.rounding.option.UpToNearestTen') },
        { id: 'DownToNearestOne', label: this.$t('dictionary.rounding.option.DownToNearestOne') },
        { id: 'DownToNearestFive', label: this.$t('dictionary.rounding.option.DownToNearestFive') },
        { id: 'DownToNearestTen', label: this.$t('dictionary.rounding.option.DownToNearestTen') },
      ],
      customerBirthDateFlatPickrSettings: {
        altInput: false,
        allowInput: true,
        locale: Polish,
        ariaDateFormat: 'Z',
        dateFormat: 'Y-m-d',
        disableMobile: true,
      },
    }
  },
  computed: {
    role() {
      return role
    },
    isThereAtLeastOneContactInformation() {
      return this.customer.phone || this.customer.email || this.customer.guardianPhone || this.customer.guardianEmail
    },
  },
  beforeDestroy() {
    this.$emit('leaving-adding-form')
  },
  methods: {
    discountFormatter(val) {
      return `${val}%`
    },
    handlePhoneNumberValidation(phoneObject) {
      this.$refs.customerFormValidation.reset()
      this.phoneNumberValid = phoneObject.valid === undefined || phoneObject.valid
    },
    handleGuardianPhoneNumberValidation(phoneObject) {
      this.$refs.customerFormValidation.reset()
      this.guardianPhoneNumberValid = phoneObject.valid === undefined || phoneObject.valid
    },
    saveCustomer() {
      this.$refs.customerFormValidation.validate()
        .then(valid => {
          if (!valid || !this.phoneNumberValid) {
            return false
          }

          if (this.customer.birthDate) {
            this.customer.birthDate = fixDateTimezone(this.customer.birthDate)
          }

          if (this.customer.id) {
            this.$store.dispatch(
              'customer/editCustomer',
              { customerId: this.customer.id, data: this.customer },
            )
              .then(() => {
                this.$emit('customer-saved', this.customer)
                window.toast.notify.success(this.$t('customers.form.notifications.updated'))
              })
              .catch(err => parseRequestError(err))
          } else {
            this.$store.dispatch(
              'customer/addCustomer',
              { data: this.customer },
            )
              .then(response => {
                this.$emit('customer-added', response.data)
                window.toast.notify.success(this.$t('customers.form.notifications.added'))
              })
              .catch(err => parseRequestError(err))
          }

          return true
        })
    },
  },
}
</script>
