<template>
  <div>
    <customer-view-statistics
      v-if="me.roles.includes(role.admin)"
      :customer="customer"
    />

    <b-card
      class="shadow-none pt-25"
      no-body
    >

      <b-tabs
        content-class="mt-1"
        fill
      >

        <b-tab
          :title="$t('customers.tabs.details.title')"
          title-item-class="app-tour-customer-view-details-tab-title"
          lazy
        >
          <customer-view-details-tab
            class="app-tour-customer-view-details-tab"
            :customer="customer"
            @updating="setUpdating"
          />
        </b-tab>

        <b-tab
          :title="$t('customers.tabs.history.title')"
          title-item-class="app-tour-customer-view-history-tab-title"
          lazy
        >
          <customer-view-history-tab
            class="app-tour-customer-view-history-tab"
            :customer="customer"
          />
        </b-tab>

        <b-tab
          v-if="me.roles.includes(role.admin)"
          :title="$t('customers.tabs.settlements.title')"
          title-item-class="app-tour-customer-view-settlements-tab-title"
          lazy
        >
          <customer-view-settlements-tab
            class="app-tour-customer-view-settlements-tab"
            :customer="customer"
          />
        </b-tab>

        <b-tab
          v-if="me.roles.includes(role.admin)"
          :title="$t('customers.tabs.additional-info.title')"
          title-item-class="app-tour-customer-view-additional-info-tab-title"
          lazy
        >
          <customer-view-additional-info-tab
            class="app-tour-customer-view-additional-info-tab"
            :customer="customer"
          />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTab, BTabs,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import CustomerViewStatistics from '@/views/organization/customers/CustomerViewStatistics'
import CustomerViewDetailsTab from '@/views/organization/customers/tabs/CustomerViewDetailsTab'
import CustomerViewHistoryTab from '@/views/organization/customers/tabs/CustomerViewHistoryTab'
import CustomerViewAdditionalInfoTab from '@/views/organization/customers/tabs/CustomerViewAdditionalInfoTab'
import CustomerViewSettlementsTab from '@/views/organization/customers/tabs/CustomerViewSettlementsTab'
import role from '@/auth/role'
import store from '@/store'

export default {
  components: {
    CustomerViewDetailsTab,
    CustomerViewHistoryTab,
    CustomerViewSettlementsTab,
    CustomerViewAdditionalInfoTab,
    CustomerViewStatistics,
    BCard,
    BTabs,
    BTab,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const updating = ref(false)
    const setUpdating = newUpdating => { updating.value = newUpdating }

    const me = computed(() => store.getters['auth/getUser'])

    return {
      updating,
      setUpdating,
      role,
      me,
    }
  },
}
</script>
