<template>
  <div>
    <b-table
      v-if="customerBalanceHistories.length > 0"
      responsive
      :items="customerBalanceHistories"
      :fields="fields"
      sort-by="createdAt"
      :sort-desc="true"
      class="mb-0"
      :per-page="pagination.itemsPerPage"
      :current-page="pagination.currentPage"
    >

      <template #cell(createdAt)="data">
        <b-badge
          variant="light-secondary"
        >
          {{ dayjs(data.item.createdAt).format($t('date-format.date')) }}
        </b-badge>
      </template>

      <template #cell(additional)="data">

        <div v-if="data.item.type === 'PaymentAccountedForClasses'">
          <div class="mb-50">
            {{ $t('customers.tabs.settlements.payment-recording') }}
          </div>
          <div v-if="data.item.settlement">
            <div
              v-for="cec in data.item.settlement.calendarEventCustomers"
              :key="`${data.item.settlement.id}_${cec.calendarEvent.id}`"
              class="font-small-2"
            >{{
              $t('customers.tabs.settlements.event-time') + ': ' + dayjs(cec.calendarEvent.startAt)
                .format($t('date-format.date-time'))
            }}</div>
          </div>
        </div>

        <div v-if="data.item.type === 'IncomingPayment'">
          <div>{{ $t('customers.tabs.settlements.incoming-payment') }}</div>
          <small>{{
            $t('customers.tabs.settlements.payment-method') + ': ' + $t(`shared.payment-method.${data.item.settlement.method}`)
          }}</small>
        </div>

        <div v-if="data.item.type === 'PaymentReturnedForCancelledClasses'">
          <div>{{ $t('customers.tabs.settlements.return-for-cancelled-classes') }}</div>
          <small v-if="data.item.calendarEvent">{{
            $t('customers.tabs.settlements.event-time') + ': ' + dayjs(data.item.calendarEvent.startAt)
              .format($t('date-format.date-time'))
          }}</small>
        </div>

        <div v-if="data.item.type === 'PaymentAccountedForLateCancelledClasses'">
          <div>{{ $t('customers.tabs.settlements.accounted-for-late-cancelled-classes') }}</div>
          <div v-if="data.item.settlement">
            <small
              v-for="cancellation in data.item.settlement.calendarEventCancellations"
              :key="`cancellation-${data.item.settlement.id}_${cancellation.id}`"
            >{{
              $t('customers.tabs.settlements.event-time') + ': ' + dayjs(cancellation.calendarEventStartAt)
                .format($t('date-format.date-time'))
            }}<br>{{
              $t('customers.tabs.settlements.cancel-event-time') + ': ' + dayjs(cancellation.cancelledAt)
                .format($t('date-format.date-time'))
            }}</small>
          </div>
        </div>

        <div v-if="data.item.type === 'PaymentReturn'">
          <div>{{ $t('customers.tabs.settlements.return') }}</div>
        </div>
        <div v-if="data.item.settlement && data.item.settlement.comment">
          <small class="font-italic">
            <feather-icon
              icon="MessageCircleIcon"
              class="mr-25"
            />
            {{ data.item.settlement.comment }}
          </small>
        </div>
        <div v-if="showPaidAtDate(data.item.settlement)">
          <small>
            {{ $t('customers.tabs.settlements.payment-date') + ': ' + dayjs(data.item.settlement.paidAt).format($t('date-format.date')) }}
          </small>
        </div>
      </template>

      <template #cell(balanceAfter)="data">
        <b-badge
          variant="light-secondary"
        >
          {{ strToDecimal(data.value) }} zł
        </b-badge>
      </template>

      <template #cell(amount)="data">
        <b-badge
          v-if="data.value > 0"
          variant="light-success"
        >
          <feather-icon
            icon="ArrowDownRightIcon"
            class="mr-25"
          />
          {{ strToDecimal(data.value) }} zł
        </b-badge>
        <b-badge
          v-else
          variant="light-danger"
        >
          <feather-icon
            icon="ArrowUpRightIcon"
            class="mr-25"
          />
          {{ strToDecimal(data.value) }} zł
        </b-badge>
      </template>

    </b-table>

    <b-pagination
      v-if="customerBalanceHistories.length > 0 && pagination.itemsPerPage < customerBalanceHistories.length"
      v-model="pagination.currentPage"
      :total-rows="totalRows"
      :per-page="pagination.itemsPerPage"
      align="right"
      size="md"
      class="mb-2 mt-1 mr-2"
    />
  </div></template>
<script>
import dayjs from 'dayjs'
import SettlementTypeDictionary from '@/dictionary/SettlementTypeDictionary'
import { strToDecimal } from '@/helpers/helpers'

export default {
  name: 'BalanceHistoryTable',
  props: {
    customerBalanceHistories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      settlementType: SettlementTypeDictionary.INCOMING_PAYMENT,
      pagination: {
        itemsPerPage: 10,
        currentPage: 1,
      },
      fields: [
        {
          key: 'createdAt',
          label: this.$t('customers.tabs.settlements.created-at'),
          sortable: true,
        },
        {
          key: 'additional',
          label: this.$t('customers.tabs.settlements.operation-type'),
          sortable: false,
          class: 'px-25',
        },
        {
          key: 'amount',
          label: this.$t('customers.tabs.settlements.amount'),
          sortable: true,
          class: 'text-right pl-50 pr-2',
        },
        {
          key: 'balanceAfter',
          label: this.$t('customers.tabs.settlements.balance-after'),
          sortable: true,
          class: 'text-right pl-50',
        },
      ],
    }
  },
  computed: {
    totalRows() {
      return this.customerBalanceHistories.length
    },
  },
  methods: {
    dayjs,
    strToDecimal,
    showPaidAtDate(settlement) {
      if (!settlement) {
        return false
      }

      if (!settlement.paidAt) {
        return false
      }

      return dayjs(settlement.createdAt).format(this.$t('date-format.date'))
              !== dayjs(settlement.paidAt).format(this.$t('date-format.date'))
    },
  },
}
</script>
