<template>
  <div class="m-1">
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <h6 class="text-muted text-uppercase font-small-3">
          {{ $t('customers.tabs.details.label.current-knowledge-level') }}
        </h6>
        <editable-item
          v-model="customer.currentKnowledgeLevel"
          value-path="currentKnowledgeLevel"
          :debounce="2500"
          :maxlength="250"
          @input="updateValue"
          @updating="$emit('updating', true)"
        />
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <h6 class="text-muted text-uppercase font-small-3">
          {{ $t('customers.tabs.details.label.target-knowledge-level') }}
        </h6>
        <editable-item
          v-model="customer.targetKnowledgeLevel"
          value-path="targetKnowledgeLevel"
          :debounce="2500"
          :maxlength="250"
          @input="updateValue"
          @updating="$emit('updating', true)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <h6 class="text-muted text-uppercase font-small-3">
          {{ $t('customers.tabs.details.label.meeting-location') }}
        </h6>
        <editable-item
          v-model="customer.defaultMeetingLocation"
          value-path="defaultMeetingLocation"
          :debounce="2500"
          :maxlength="250"
          @input="updateValue"
          @updating="$emit('updating', true)"
        />
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <h6 class="text-muted text-uppercase font-small-3">
          {{ $t('customers.tabs.details.label.meeting-platform') }}
        </h6>
        <editable-item
          v-model="customer.defaultMeetingPlatform"
          value-path="defaultMeetingPlatform"
          :debounce="2500"
          :maxlength="250"
          @input="updateValue"
          @updating="$emit('updating', true)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-1">
        <h6 class="text-muted text-uppercase font-small-3">
          {{ $t('customers.tabs.details.label.coursebook') }}
        </h6>
        <editable-item
          v-model="customer.coursebook"
          value-path="coursebook"
          :debounce="2500"
          :maxlength="250"
          @input="updateValue"
          @updating="$emit('updating', true)"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col class="mb-1">
        <h6 class="text-muted text-uppercase font-small-3">
          {{ $t('customers.tabs.details.label.notes') }}
        </h6>
        <editable-textarea
          v-model="customer.notes"
          value-path="notes"
          :debounce="2500"
          :maxlength="10000"
          :inline="false"
          @input="updateValue"
          @updating="$emit('updating', true)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import EditableItem from '@/views/organization/customers/tabs/EditableItem'
import { parseRequestError } from '@/helpers/helpers'
import EditableTextarea from '@/views/organization/customers/tabs/EditableTextarea'

export default {
  components: {
    EditableItem,
    EditableTextarea,
    BRow,
    BCol,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateValue(val, obj) {
      this.$store.dispatch(
        'customer/patchCustomer',
        { customerId: this.customer.id, data: obj },
      )
        .then(this.$emit('updating', false))
        .catch(parseRequestError)
    },
  },
}
</script>
